.optionContainer {
  padding-top: 0.5rem;
}

.normalWeight {
  font-weight: normal !important;
}

.soundIcon {
  margin-right: 0.5rem;;
  font-weight: normal !important;
}

.soundButton {
  float: right;
  margin-right: 50rem;
}
