.brugerkodeLink {
    color: black;
    text-decoration: underline;
}

.brugerkodeLink:visited {
    color: black;
    text-decoration: underline;
}

.brugerkodeLink:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

.container {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.toggleArchiveButton {
    display: flex;
    align-items: flex-end;
}

.header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 0 !important;
}
