.userLink {
    cursor: pointer;
    color: black;
}

div button.noneDecoration {
    text-decoration: none;
}

div button.noneDecoration:hover{
    text-decoration: underline;
}