@font-face {
    font-family: "Lato";
    src: local("Lato"), url(../fonts/Lato-Regular.ttf) format("truetype");
}

.megatron-ot-enudafxbilleder .megatron-opgave-svarmuligheder div div div {
    border: 3px solid #D7D7D7;
}

.megatron-ot-enudafxbilleder .megatron-opgave-svarmuligheder div {
    max-width: 45rem !important;
    padding-top: 1.5rem;
}

.megatron-pagecontent .action-buttons button {
    float: right;
    border-radius: 2rem;
}

.form {
    display: inline-block;
    height: 15px;
}

.megatron-pagecontent h1 {
    margin-top: 25px;
    margin-bottom: 30px;
}

.megatron-page h1 {
    margin-top: 25px;
    margin-bottom: 30px;
}

.megatron-ot-enudafxknapper h1.megatron-opgave-tekst {
    font-size: 2.0em;
}

.megatron-ot-enudafxknapper div.megatron-opgave-svarmulighed {
    margin-top: 2em;
    min-width: 100%;
}

.megatron-ot-enudafxknapper .megatron-opgave-svarmulighed > div {
    max-width: 100% !important;
}

.megatron-ot-enudafxknapper div.megatron-opgave-svarmulighed div:nth-last-of-type(1)  {
    position: absolute;
}

.megatron-ot-enudafxknapper div.megatron-opgave-svarmulighed div div:nth-last-of-type(1)  {
    position: relative;
}

.megatron-ot-enudafxknapper .megatron-opgave-svarmulighed > div div button  {
    padding: 0.5em 1.0em !important;
}

.megatron-ot-enudafxbilleder div div div button {
    display: none;
}

.megatron-ot-enudafxbilleder h1.megatron-opgave-tekst {
    font-size: 2.0em;
}

.megatron-opgave-sammensat-tekst {
    color: #1a1a1a;
    size: 20px;
    font-size: 1.8rem;
    line-height: 3.2rem;
    max-width: 66ch;
    letter-spacing: -0.15px;
    display: block;
}

.megatron-opgave-tekst {
    margin-bottom: 15px;
    display: inline;
    white-space: normal;
}

.megatron-opgave-tekst::after {
    content: " ";
    margin-bottom: 15px;
    display: normal;
    white-space: normal;
}

.megatron-pagecontent img {
    margin-top: 15px;
    margin-bottom: -10px;
}

.megatron-ot-optilenudafxtekst .megatron-opgave-tekst div.megatron-opgave-svarmuligheder {
    display: inline-block;
    margin-top: 0px;
    height: calc(2rem + 8px * 2);
    line-height: 2rem;
    max-height: 23px;
}

.megatron-ot-optilenudafxtekst .megatron-opgave-tekst div.megatron-opgave-svarmuligheder > * {
    background-color: #e6e9ea;
    display: inline-block;
    padding-right: 0.5rem;
    padding-bottom: 0.6rem;
}

.megatron-ot-optilenudafxtekst .megatron-opgave-tekst div.megatron-opgave-svarmuligheder > * label {
    padding-left: 2.0rem !important;
    max-height: 23px !important;
    margin-bottom: 0px;
}

.megatron-ot-optilenudafxtekst .megatron-opgave-tekst div.megatron-opgave-svarmuligheder > * label::before {
    height: 14px;
    width: 14px;
    margin-top: 1rem;
}

.megatron-ot-optilenudafxtekst .megatron-opgave-tekst div.megatron-opgave-svarmuligheder > * label::after {
    height: 6px;
    width: 6px;
    top: 7px;
    left: 4px;
    margin-top: 1rem;
}

.opgavenummer {
    margin-top: 15px;
    margin-left: 15px;
    color: #747474;
    font-size: 13px;
    text-align: right;
}

.megatron-opgave-svarmulighed img {
    margin-top: -5px;
}

.megatron-ot-optilenudafx .megatron-opgave-tekst {
    font-weight: 600;
}

.megatron-ot-optilenudafx .megatron-opgave-svarmuligheder {
    margin-top: 15px;
}

.megatron-ot-optilenudafx {
    margin-top: 25px;
}

.megatron-opgave-tekst .mb-7 {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.megatron-opgave-svarmuligheder .form-select {
    padding-left: 8px;
    padding-right: 35px;
    margin-top: 0px;
    height: calc(2rem + 8px * 2);
    line-height: 2rem;
    background-color: white;
}

.megatron-ot-optilenudafxtekst .megatron-opgave-tekst {
    line-height: 200%;
}

img {
    margin: 5px;
}

.svarmulighed-image {
    display: inline-block;
    text-align: center;
}

.svarmulighed-image img {
    margin-bottom: 30px !important;
}

.svarmulighed-image .form-checkbox + label::before {
    position: absolute !important;
    left: calc(50% - 18px) !important;
    top: 90% !important;
}

.svarmulighed-image .form-checkbox.checkbox-large + label {
    padding-left: 0px;
}

.svarmulighed-image .form-checkbox:checked + label:after {
    position: absolute !important;
    left: calc(50% - 12px) !important;
    top: calc(90% + 3px) !important;
}

.megatron-afsnit-tekst {
    font-size: 1.8rem;
}

.megatron-opgave-tekst {
    font-size: 1.8rem;
}

.headerlogo {
    height: 35px;
    width: 35px;
}

.crown {
    display: flex;
    height: 34px;
    padding-right: 10px;
    width: auto;
}

.portal-header-inner a.stillogo, .portal-header-inner button.stillogo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-family: Lato, system, serif;
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.megatron-afsnit-tekst {
    font-size: 1.8rem;
}

.megatron-opgave-tekst {
    font-size: 1.8rem;
}

.opgave td {
    padding-left: 20px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.displayNone {
    display: none;
}

.portal-header-inner .row {
    justify-content: flex-start;
    align-items: center;
}

.portal-header {
    border-bottom: 4px solid !important;
    color: #bfbfbf;
}

// Afslut-tab should be right aligned on Megatron
.megatron-subpage .nav-primary {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: -12px;
}

.megatron-subpage .navbar-inner {
    padding-right: 0px !important;
}

.megatron-subpage .nav-primary li:last-child {
    margin-left: auto;
    margin-right: 0px;
}

.headertitle {
    margin: 0px;
}

.hovedopgave {
    cursor: pointer;
}

.detail {
    text-decoration: underline;
}

.detail-laesetest {
    text-decoration: underline;
}

.detail::before {
    content: "";
    display: inline-block;
    background-position: -10px -8px;
    background-repeat: no-repeat;
    background-size: 3.4rem;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 4px;
    margin-bottom: -2px !important;
}

.detail_open::before {
    background-image: url("../img/arrow-down.svg");
}

.detail_closed::before {
    background-image: url("../img/arrow-right.svg");
}

.brugerkoder {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}

.brugerkoder > .item {
    margin: 3px;
}
// Material Table custom styles
.MuiTableRow-root:nth-child(even) {
    background-color: #f5f5f5;
}

.MuiTypography-caption {
    font-size: 1.1rem !important;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 24px !important;
}

.MuiSvgIcon-root {
    font-size: 24px !important;
}

.MuiTablePagination-select {
    font-size: 1.1rem !important;
    letter-spacing: 0.03333em !important;
    color: rgba(0, 0, 0, 0.54) !important;
    box-sizing: inherit !important;
}

.MuiPaper-root > ul > li::before {
    content: "" !important;
}

.MuiCheckbox-colorSecondary.Mui-checked{
    color: #1a1a1a !important;
}

.MuiToolbar-root {
    background-color: white !important;
}

.MuiMenuItem-root {
    font-size: 12px !important;
}

// Footer at bottom
#root {
    position: relative !important;
    min-height: 100vh !important;
}

// Fully answed opgave
.sidenav-list li.megatron-full-answer.active.current {
    border-left: 4px solid #0003b3 !important;
}

li.megatron-full-answer {
    border-left: 4px solid #bfbfbf !important;
}

.sidenav-list li {
    border-left: 4px solid #ffffff;
}

// Custom pages in Megatron
.hide-button {
    display: none;
}

.button-right {
    float: right;
}

.example-afsnit-1 {
    display: block;
    padding-bottom: 1rem;
}

.example-headline {
    font-weight: bold;
    display: block;
}

.options-afsnit-1 {
    background-color: #e6e9ea;
    white-space: nowrap;
}

.correct-1 {
    background-position: 0px -22px !important;
}

.answer {
    background-image: url('../../assets/img/custom_radio.png');
    display: inline;
    cursor: pointer;
    padding-left: 20px;
    margin-left: 5px;
    background-repeat: no-repeat;
}

div.example-afsnit-2-container {
    display: flex;
    justify-content: center;
}

.second-row {
    padding-top: 2rem;
}

.first-column {
    padding-right: 2rem;
}

div.example-afsnit-2-container div div img {
    border: 3px solid #D7D7D7;
}

.picture-answer {
    border: 3px solid green !important;
}

.example-afsnit-3-container-ramme {
    margin-top: 2rem;
    //margin: auto;
    border: 0.5px #979090 solid;
    border-radius: 15px;
    padding: 2.5em;
    background-color: #f1f0f0;
    //box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
}

.example-afsnit-3-container-ramme * {
    padding: 0.4em;
}

.example-afsnit-3-container {
    flex-flow: row wrap;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}

.example-afsnit-03-button-container {
    justify-content: center;
    border-right: none;
    display: flex;
    -webkit-box-pack: center;
    box-sizing: border-box;
    flex: 1 1 0px;
}

.button-afsnit-3-correct {
    background-color: lightgreen !important;
}

.button-afsnit-3 {
    max-width: 10em;
    min-height: 2em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.2em;
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    font-family: 'Roboto',serif;
    font-weight: 400;
    font-size: 1.5em;
    color: #000000;
    background-color: #f0ecec;
    //box-shadow: inset 0 -0.6em 1em -0.35em rgb(0 0 0 / 17%), inset 0 0.6em 2em -0.3em rgb(255 255 255 / 15%), inset 0 0 0em 0.05em rgb(255 255 255 / 12%);
    text-align: center;
    position: relative;
    word-wrap: break-word;
}
