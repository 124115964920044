.link {
    cursor: pointer;
    text-decoration: none;
}

.header {
    padding-bottom: 9px !important;
}

li button.noneDecoration {
    text-decoration: none;
    padding-top: calc(16px - 1px);
}