.table {
    text-indent: 10px;
}
.samletResultat {
    background-color: #F5F5F5 !important;
    font-weight: 600;
}

.result {
    column-width: 100px;
}
