.checkbox {
    margin-bottom: 1.0rem;
}

.checkboxLabel {
    padding-left: 0.5rem;
}

.checkboxLabel::after {
    content: '\A';
    white-space: pre;
}

.labelText {
    display: block;
    margin-bottom: 1.0rem;
}

.infoDiv {
    margin-bottom: 3.0rem;
}
