.submitCancel {
    display: flex;
    margin-top: 1em;
}

.submitButton {
    margin-right: 1em;
}

.button {
    margin-top: 2rem;
    margin-right: 1.5rem;
}
