.list > li::before {
    content: "";
}

.list > li {
    padding-left: 0;
}
a {
    cursor: pointer;
}

li button.noneDecoration {
    text-decoration: none;
}

li button.noneDecoration:hover {
    text-decoration: underline;
}