.infoText {
    line-height: 150%;
    display: block;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

.textLabel {
    padding-top: 7px;
    margin-right: 78px;
}
