.brugerkodeLink {
    color: black;
    text-decoration: underline;
}

.brugerkodeLink:visited {
    color: black;
    text-decoration: underline;
}

.brugerkodeLink:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

.helperTextButton {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.toggleArchiveButton {
    display: flex;
    align-items: flex-end;
}

.helperText {
    line-height: 150%;
}

.button {
    margin-top: 2rem;
    margin-right: 1.5rem;
}

.iconQuestionMark {
    margin-right: 0.5rem;
}

.buttonHelperText {
    color: #747474;
    font-size: 15px;
}

.holdIcon {
    padding-left: 1.0rem;
}
