.brugerkodeLink {
  color: black;
  text-decoration: underline;
}

.brugerkodeLink:visited {
  color: black;
  text-decoration: underline;
}

.brugerkodeLink:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}
