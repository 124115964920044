.container {
    display: flex;
}

.content {
    vertical-align: bottom;
    padding-left: 15px;
    flex-grow: 1;
    margin-top: 4px;
}

.closeIcon {
    margin-top: 4px;
    width: 25px;
    margin-left: 20px;
}

.svg {
    vertical-align: top;
}

.messageIcon {
    margin-top: 8px;
    vertical-align: middle;
}

.toastButton {
    background: none;
    border: none;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 8px;
}

.toastButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.toastMain {
    color: white;
    width: 100%;
    min-height: 50px;
    padding: 12px;
    border-radius: 7px;
}

.toastSuccess {
    background-color: #28a745;
}

.toastError {
    background-color: #dc3545;
}

.toastInfo {
    background-color: #17a2b8;
}

.toastWarning {
    background-color: #ffc107;
}
