.toastContainerMain {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.toastContainerMain > div {
    color: white;
    width: 100%;
    margin-bottom: 10px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 7px;
    animation: slide-in 0.2s;
    transition: all 0.3s ease;
}

@keyframes slide-in {
    from {
        transform: translateX(30px);
        opacity: 0;
    }
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}
