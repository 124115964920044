.submitCancel {
    display: flex;
    margin-top: 1em;
}

.submitButton {
    margin-right: 1em;
}

.radioButton {
    margin-top: 1.3rem;
}

.selectionContainer {
    float: left;
    margin-right: 2.0rem;
}

.radioButtonContainer {
    display: block;
}

.input {
    margin-top: 0 !important;
    vertical-align: center;
}

.optionContainer {
    padding-top: 1.0rem;
}

.firstOptionContainer {
    padding-top: 0.5rem;
    padding-bottom: 0.8rem;
}
