.container {
    width: 100%;
}

.image {
    margin: 0;
    max-width: 100%
}

.rightDiv {
    width: 66%;
    float: right;
}

.inputContainer {
    padding: 2.0rem 2.0rem 5.0rem 2.0rem;
    background-color: #CCE4EA;
    width: 34%;
    float: left;
    min-height: 301px;
}

.infoText {
    line-height: 150%;
    display: block;
}

.textContainer {
    padding-top: 3.0rem;
}
