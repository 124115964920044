.link {
    cursor: pointer;
    color: black;
}

.list > li::before {
    content: "";
}

.list > li {
    padding-left: 0;
}