.accessUserDataTable tr {
    vertical-align: top;
    text-align: left;
    width: 100%;
}
.accessUserDataTable {
    width: 100%;
}

.begrundelseLabel {
    width: 20%;
    vertical-align: top;
}

.begrundelseInput {
    width: 70% !important;
    max-width: 70% !important;
    height: 200px !important;
    resize: none;
}

.submitCancel {
    display: flex;
    margin-top: 1em;
}

.submitButton {
    margin-right: 1em;
}

.archiveTextSupportLabel {
    padding-right: 20px;
}
