.infoText {
    line-height: 150%;
}

.label {
    padding-top: 1.6rem;
}

.input {
    margin-bottom: 1.6rem;
}
